import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../api/axiosInstance";

export const useGetBusinesses = () => {
  const get_businesses = async () => {
    const res = await apiClient.get("/business");
    return res.data.business;
  };

  return useQuery({
    queryKey: ["business"],
    queryFn: get_businesses,
  });
};
