import React, { useState } from "react";
import { apiClient } from "../api/axiosInstance";
import { useNavigate } from "react-router-dom";
import { normalizeData } from "utils/generalutils/normalisePhoneNumber";
import { toast } from "react-toastify";

export const AuthContext = React.createContext();

const AuthProvider = (props) => {
  let navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [login_error, setLoginError] = useState("");
  const [auth_success, setAuthSuccess] = useState("");

  const signIn = async (params) => {
    try {
      // Make API request to login
      const response = await apiClient.post("/users/login", {
        username: params.email,
        password: params.password,
      });

      const { token, user } = response.data; // Adjust according to your API response

      // Save user data and token
      setUser(user);
      setLoggedIn(true);
      // Store token in localStorage or other secure storage

      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));

      setAuthSuccess("Login successful..");
      toast.success("Login successful..");

      setTimeout(() => {
        setAuthSuccess("");
        navigate("/", { replace: true });
      }, 2500);
    } catch (error) {
      // Handle error (e.g., show a notification or set an error state)
      // If there's an error, store it in the login_error state
      setLoginError(
        error.response?.data?.message || "Login failed. Try again."
      );

      setTimeout(() => {
        setLoginError("");
      }, 4000);
    }
  };

  const signUp = async (params) => {
    const { email, password, username, fullname, phone_number } = params;
    try {
      await apiClient.post("/users", {
        username,
        email,
        password,
        role_code: "CLIENT",
        fullname,
        phone_number: normalizeData([phone_number]).join(""),
      });

      setAuthSuccess("Registration successful, login to continue..");
      toast.success("Registration successful, login to continue!");

      setTimeout(() => {
        setAuthSuccess("");
      }, 4000);

      navigate("/sign-in", { replace: true });
    } catch (error) {
      console.error("Sign up failed:", error);
      // Handle error (e.g., show a notification or set an error state)
      // If there's an error, store it in the login_error state
      setLoginError(
        error.response?.data?.message || "Registration failed. Try again."
      );

      setTimeout(() => {
        setLoginError("");
      }, 4000);
    }
  };

  const logOut = () => {
    setUser(null);
    setLoggedIn(false);
    localStorage.clear();
    navigate("/"); // Redirect to login page or any other appropriate route
  };

  // send recovery otp
  const sendRecoveryOTP = async (params) => {
    try {
      // Make API request to login
      const response = await apiClient.post("/forgot-password", {
        // email: params.email,
        email: params.email.includes("@")
          ? params.email
          : normalizeData([params.email]).join(""),
        phone_number: normalizeData([params.email]).join(""),
      });

      toast.success("OTP sent successful..");

      // update a variable to display inputs for submitting otp
    } catch (error) {
      // Handle error (e.g., show a notification or set an error state)
      // If there's an error, store it in the login_error state
      setLoginError(
        error.response?.data?.message || "Login failed. Try again."
      );

      setTimeout(() => {
        setLoginError("");
      }, 4000);
    }
  };

  // selected search query
  const [searchQuery, setSearchQuery] = useState({});

  // form state
  const [formState, setFormState] = useState({
    startDate: null,
    endDate: null,
    room: 1,
    guest: 1,
  });

  return (
    <AuthContext.Provider
      value={{
        formState,
        setFormState,
        searchQuery,
        setSearchQuery,
        loggedIn,
        logOut,
        signIn,
        signUp,
        user,
        setLoginError,
        login_error,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
