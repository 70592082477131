import { AuthContext } from "context/AuthProvider";
import React, { useState, useRef, useEffect, useContext } from "react";

const PlaceSearch = ({ hasBorder, onPlaceSelect, searchData }) => {
  const styles = {
    wrapper: {
      position: "relative",
      width: "100%",
      maxWidth: "600px",
      margin: "0 auto",
      fontFamily: "Arial, sans-serif",
    },

    inputContainer: {
      position: "relative",
      width: "100%",
    },

    input: {
      width: "100%",
      padding: "12px 40px 12px 16px",
      fontSize: "16px",
      border: hasBorder ? "1px solid #ddd" : "1px solid transparent",
      borderRadius: "8px",
      boxShadow: hasBorder ? "0 2px 4px rgba(0, 0, 0, 0.05)" : "0,0,0",
      outline: "none",
      boxSizing: "border-box",
      backgroundColor: hasBorder ? "#f8f8f8" : "transparent", // Default background
      transition: "all 0.2s ease", // Smooth transition
    },

    searchIcon: {
      position: "absolute",
      right: "12px",
      top: "50%",
      transform: "translateY(-50%)",
      width: "20px",
      height: "20px",
      color: "#666",
    },
    dropdown: {
      position: "absolute",
      top: "calc(100% + 4px)",
      left: "0",
      width: "100%",
      backgroundColor: "white",
      border: "1px solid #ddd",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      maxHeight: "400px",
      overflowY: "auto",
      zIndex: 1000,
    },
    resultItem: {
      display: "flex",
      alignItems: "flex-start",
      padding: "12px 16px",
      cursor: "pointer",
      gap: "12px",
      borderBottom: "1px solid #eee",
    },
    resultItemHover: {
      backgroundColor: "#f5f5f5",
    },
    locationIcon: {
      width: "20px",
      height: "20px",
      color: "#666",
      flexShrink: 0,
      marginTop: "3px",
    },
    placeInfo: {
      display: "flex",
      flexDirection: "column",
    },
    placeName: {
      fontWeight: "500",
      marginBottom: "4px",
    },
    placeAddress: {
      fontSize: "14px",
      color: "#666",
    },
    message: {
      padding: "16px",
      textAlign: "center",
      color: "#666",
    },
  };
  const { setSearchQuery, searchQuery } = useContext(AuthContext);

  const [query, setQuery] = useState(
    searchQuery?.name ? searchQuery?.name : ""
  );
  const [results, setResults] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setSearchQuery({
      name: value,
    });

    if (value.length >= 1) {
      const filtered = searchData.filter(
        (place) =>
          place.name.toLowerCase().includes(value.toLowerCase()) ||
          place.address.toLowerCase().includes(value.toLowerCase())
      );
      setResults(filtered);
      setIsOpen(true);
    } else {
      setResults([]);
      setIsOpen(false);
    }
  };

  const handleResultClick = (place) => {
    setQuery(place.name);
    onPlaceSelect(place);
    setIsOpen(false);
  };

  return (
    <div style={styles.wrapper} ref={wrapperRef}>
      <div style={styles.inputContainer}>
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Search places..."
          style={styles.input}
          onFocus={(e) => {
            if (hasBorder) {
              e.target.style.backgroundColor = "#ffffff";
              e.target.style.borderColor = "#4a90e2"; // Blue border
            }
          }}
          onBlur={(e) => {
            if (hasBorder) {
              e.target.style.backgroundColor = "#f8f8f8";
              e.target.style.borderColor = "#ddd";
            }
          }}
        />

        {hasBorder && (
          <svg
            style={styles.searchIcon}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        )}
      </div>

      {isOpen && results.length > 0 && (
        <div style={styles.dropdown}>
          {results.map((place, index) => (
            <div
              key={index}
              style={{
                ...styles.resultItem,
                ...(hoveredIndex === index ? styles.resultItemHover : {}),
              }}
              onClick={() => handleResultClick(place)}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <svg
                style={styles.locationIcon}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <div style={styles.placeInfo}>
                <div style={styles.placeName}>{place.name}</div>
                <div style={styles.placeAddress}>{place.address}</div>
              </div>
            </div>
          ))}
        </div>
      )}

      {isOpen && query && results.length === 0 && (
        <div style={styles.dropdown}>
          <div style={styles.message}>No places found matching your search</div>
        </div>
      )}
    </div>
  );
};

export default PlaceSearch;
