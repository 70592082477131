import React, { useContext } from "react";
import isEmpty from "lodash/isEmpty";
import { useNavigate, useLocation } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import MapAutoComplete from "components/Map/MapAutoComplete";
import { setStateToUrl, getStateFromUrl } from "library/helpers/url_handler";
import { mapDataHelper } from "components/Map/mapDataHelper";
import { LISTING_POSTS_PAGE } from "settings/constant";
import { NavbarSearchWrapper } from "./Header.style";
import PlaceSearch from "components/Map/PlaceSearch";
import { useGetBusinesses } from "hooks/getRequests/useGetBusinesses";
import { AuthContext } from "context/AuthProvider";

export default function NavbarSearch() {
  let navigate = useNavigate();
  let location = useLocation();

  const updateValueFunc = (value) => {
    const { searchedPlaceAPIData } = value;
    let tempLocation = [];
    const mapData = !isEmpty(searchedPlaceAPIData)
      ? mapDataHelper(searchedPlaceAPIData)
      : [];
    if (!isEmpty(mapData) && mapData.length !== 0) {
      mapData.forEach((singleMapData) =>
        tempLocation.push({
          lat: singleMapData ? singleMapData.lat.toFixed(3) : null,
          lng: singleMapData ? singleMapData.lng.toFixed(3) : null,
        })
      );
    }

    const searchLocation = tempLocation ? tempLocation[0] : {};
    if (!isEmpty(searchLocation)) {
      const state = getStateFromUrl(location);
      const query = {
        ...state,
        location: searchLocation,
      };
      const search = setStateToUrl(query);
      navigate(LISTING_POSTS_PAGE, { replace: true, state: search });
    }
  };

  // In your parent component:

  // Fetch hotels and rentals

  const { data: businesses, isFetching: fetchingBusinesses } =
    useGetBusinesses();

  // filter hotels only
  // const hotels_list = businesses?.filter(
  //   (business) => business.business_type === "Hotel"
  // );

  const formatBusinessData = (apiResponse) => {
    if (!Array.isArray(apiResponse)) {
      return [];
    }

    return apiResponse.map((business) => ({
      name: business.business_fullname,
      address:
        `${business.business_address?.village || ""}, ${business.business_address?.city || ""}, ${business.business_address?.country || ""}`
          .replace(/^[,\s]+|[,\s]+$/g, "")
          .replace(/,\s*,/g, ","),
    }));
  };

  const { searchQuery, setSearchQuery } = useContext(AuthContext);

  const handlePlaceSelect = (place) => {
    console.log("Selected place:", place);
    // Handle the selected place
    setSearchQuery(place);
  };

  return (
    <NavbarSearchWrapper className="navbar_search">
      <PlaceSearch
        hasBorder={true}
        onPlaceSelect={handlePlaceSelect}
        searchData={formatBusinessData(businesses)}
        // apiEndpoint="your-api-endpoint"
      />
      {/* <MapAutoComplete updateValue={(value) => updateValueFunc(value)} /> */}
      {/* <FiSearch /> */}
    </NavbarSearchWrapper>
  );
}
